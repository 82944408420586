/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_DOCS, PATH_APP_AUTH } from './index';
// Types
import { TypeRouteItem } from '../@types';
// Components
import { Loadable, AuthGuard } from '../components';
// Providers
import { FirebaseDocumentsProvider } from '../contexts';
// Layouts
import { DashboardLayout } from '../layouts';
// Navigation configuration
import { docsNavBarConfig } from './index';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Authentication Pages
const MaintenancePage = Loadable(lazy(() => import('../pages/maintenance/index')));
const DocsPage = Loadable(lazy(() => import('../pages/docs/DocsPage')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const docsRoutes: TypeRouteItem = {
  path: PATH_APP_DOCS.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <DashboardLayout navConfig={docsNavBarConfig} />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_APP_DOCS.dashboardFull} replace />, index: true },
    // Dashboard
    {
      path: PATH_APP_DOCS.dashboard,
      element: <MaintenancePage />
    },
    // Process section - REDIRECT -
    {
      path: PATH_APP_DOCS.active.root,
      element: <Navigate to={PATH_APP_DOCS.active.contractsFull} replace />,
      index: true
    },
    // Process section - CONTRACTS -
    {
      path: PATH_APP_DOCS.active.contracts,
      element: <FirebaseDocumentsProvider documentType={'contracts'} key={Math.random()}>
        <DocsPage page_type={'contracts'} page_view={'list'} editable={true} />
      </FirebaseDocumentsProvider>
    },
    {
      path: PATH_APP_DOCS.active.contractCreate,
      element: <FirebaseDocumentsProvider documentType={'templates'}>
        <DocsPage page_type={'contracts'} page_view={'create'} editable={false} />
      </FirebaseDocumentsProvider>
    },
    {
      path: PATH_APP_DOCS.active.contractEdit,
      element: <DocsPage page_type={'contracts'} page_view={'edit'} editable={false} />
    },
    {
      path: PATH_APP_DOCS.active.contract,
      element: <DocsPage page_type={'contracts'} page_view={'view'} editable={false} />
    },
    // Process section - ACTS -
    {
      path: PATH_APP_DOCS.active.acts,
      element: <DocsPage page_type={'acts'} page_view={'list'} editable={false} />
    },
    {
      path: PATH_APP_DOCS.active.actCreate,
      element: <DocsPage page_type={'acts'} page_view={'create'} editable={false} />
    },
    {
      path: PATH_APP_DOCS.active.actEdit,
      element: <DocsPage page_type={'acts'} page_view={'edit'} editable={false} />
    },
    {
      path: PATH_APP_DOCS.active.act,
      element: <DocsPage page_type={'acts'} page_view={'view'} editable={false} />
    },
    // Process section - DOCUMENTS -
    {
      path: PATH_APP_DOCS.active.documents,
      element: <DocsPage page_type={'documents'} page_view={'list'} editable={false} />
    },
    {
      path: PATH_APP_DOCS.active.documentCreate,
      element: <DocsPage page_type={'documents'} page_view={'create'} editable={false} />
    },
    {
      path: PATH_APP_DOCS.active.documentEdit,
      element: <DocsPage page_type={'documents'} page_view={'edit'} editable={false} />
    },
    {
      path: PATH_APP_DOCS.active.document,
      element: <DocsPage page_type={'documents'} page_view={'view'} editable={false} />
    },

    // Personnel section - REDIRECT -
    {
      path: PATH_APP_DOCS.archive.root,
      element: <Navigate to={PATH_APP_DOCS.archive.contractsFull} replace />,
      index: true,
    },
    // Personnel section - CONTRACTS -
    {
      path: PATH_APP_DOCS.archive.contracts,
      element: <FirebaseDocumentsProvider documentType={'contracts'} key={Math.random()}>
        <DocsPage page_type={'contracts'} page_view={'list'} editable={true} archive={true} />
      </FirebaseDocumentsProvider>
    },
    // Personnel section - ACTS -
    {
      path: PATH_APP_DOCS.archive.acts,
      element: <MaintenancePage />
    },
    // Personnel section - DOCUMENTS -
    {
      path: PATH_APP_DOCS.archive.documents,
      element: <MaintenancePage />
    },

    // Reports section - REDIRECT -
    {
      path: PATH_APP_DOCS.reports.root,
      element: <Navigate to={PATH_APP_DOCS.reports.contractsFull} replace />
    },
    // Reports section - CONTRACTS -
    {
      path: PATH_APP_DOCS.reports.contracts,
      element: <MaintenancePage />
    },
    // Reports section - DOCUMENTS -
    {
      path: PATH_APP_DOCS.reports.documents,
      element: <MaintenancePage />
    },
    // Reports section - ACTS -
    {
      path: PATH_APP_DOCS.reports.acts,
      element: <MaintenancePage />
    },

    // Analytics section - REDIRECT -
    {
      path: PATH_APP_DOCS.analytics.root,
      element: <Navigate to={PATH_APP_DOCS.analytics.contractsFull} replace />
    },
    // Reports section - CONTRACTS -
    {
      path: PATH_APP_DOCS.analytics.contracts,
      element: <MaintenancePage />
    },
    // Reports section - DOCUMENTS -
    {
      path: PATH_APP_DOCS.analytics.documents,
      element: <MaintenancePage />
    },
    // Reports section - ACTS -
    {
      path: PATH_APP_DOCS.analytics.acts,
      element: <MaintenancePage />
    }
  ]
};

export default docsRoutes;
