/**
 * @description - The firestore database paths
 */

// Common
const FIELD_UID = 'uid';
const FIELD_COMPANY_UID = 'company_uid';
const FIELD_USER_UID = 'user_uid';
const FIELD_ACTIVE = 'active';
const FIELD_CREATED = 'created';
const FIELD_UPDATED = 'updated';
const FIELD_PERMISSION = 'permission';

// Employees Paths
const COLLECTION_EMPLOYEES = 'employees';
const FIELD_PLATFORMS = 'platforms';

// Employees paths
export const employeesPaths = {
  COLLECTION_EMPLOYEES,
  FIELD_COMPANY_UID,
  FIELD_ACTIVE,
  FIELD_USER_UID,
  FIELD_PLATFORMS,
}

// User based
const COLLECTION_USERS = 'users';

// User paths
export const userPaths = {
  COLLECTION_USERS,
  FIELD_UID,
};

// Forms based
const COLLECTION_FORMS = 'forms';
const FIELD_FORM_TYPE = 'form_type';
const VALUE_FORM_TYPE__REQUEST = 'request';

export const formsPaths = {
  COLLECTION_FORMS,
  FIELD_COMPANY_UID,
  FIELD_ACTIVE,
  FIELD_FORM_TYPE,
  FIELD_CREATED,
  VALUE_FORM_TYPE__REQUEST,
}

// Projects based
const COLLECTION_PROJECTS = 'projects';
const FIELD_PROJECT_TYPE = 'project_type';

export const projectsPaths = {
  COLLECTION_PROJECTS,
  FIELD_ACTIVE,
  FIELD_PROJECT_TYPE,
  FIELD_PERMISSION,
  FIELD_COMPANY_UID,
  FIELD_CREATED,
}

const COLLECTION_DOCUMENTS = 'documents';
const COLLECTION_CONTENTS = 'contents';
export const documentPaths = {
  COLLECTION_DOCUMENTS,
  COLLECTION_CONTENTS,
}
