/**
 * @description - The all services that are related to the project's data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  collection,
  Timestamp
} from 'firebase/firestore';
// Paths
import { projectsPaths } from '../configs';
// Local type
import { TypePlatforms } from '../@types';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The project type that was fetched from the server.
 */
export type Project = {
  company_uid: string,
  uid: string,
  manager_uid: string,
  display_name: string,
  created: Timestamp | null,
  updated: Timestamp | null,
  permission: string[],
  project_type: string,
}

/**
 * @description - The method is fetching all projects that are related to the appropriate user.
 * @param platform - The project type that needs to fetch for the specific platform
 * @param onSuccess - On success method, is executing whenever the method completed successfully. Passing the result
 * list to the void function that will be used in the parent.
 * @param onFail - On failed method, is executing whenever there is any issue during the data fetching
 * @param companyUid - The selected company UID,
 * @param userUid - The selected user uid which is doing the fetching process
 * @param companies - The companies uid list, that need to be checked for the query
 */
export function fetchAllProjectsForPlatform(
  platform: TypePlatforms,
  onSuccess: (allProjects: Project[]) => void,
  onFail: (error: Error | string) => void,
  companyUid?: string,
  userUid?: string,
  companies?: string[],
) {
  // Only if the user exists and the user correspond to any company
  if (userUid && companies && companies.length > 0 && companyUid) {
    // Generating the forms' database reference
    const firestoreDb = getFirestore();
    const formsDbReference = collection(
      firestoreDb,
      projectsPaths.COLLECTION_PROJECTS
    );
    // Query for the forms
    const allProjectsWithSpecificTypeQuery = query(
      formsDbReference,
      where(projectsPaths.FIELD_ACTIVE, '==', true),
      // Only first 10 items are allowed to be checked
      where(projectsPaths.FIELD_COMPANY_UID, '==', companyUid),
      // Only platform based projects needs to be fetched and displayed
      where(projectsPaths.FIELD_PROJECT_TYPE, '==', platform.toLowerCase()),
      // Only withe proper permission data needs to be fetched
      where(projectsPaths.FIELD_PERMISSION, 'array-contains', userUid),
      //
      orderBy(projectsPaths.FIELD_CREATED, 'desc'),
      limit(50)
    );
    // Trying to fetch the request forms
    getDocs(allProjectsWithSpecificTypeQuery)
      .then((querySnapshot) => {
        const listOfPlatformBasedProjects: Project[] = [];
        querySnapshot.forEach((projectSnapshot) => {
          const projectServerData = projectSnapshot.data();
          if (projectServerData) {
            listOfPlatformBasedProjects.push({
              uid: projectServerData.uid ?? 'N/A',
              display_name: projectServerData.display_name ?? 'N/A',
              company_uid: projectServerData.company_uid ?? 'N/A',
              manager_uid: projectServerData.manager_uid ?? 'N/A',
              created: projectServerData.created ?? null,
              updated: projectServerData.updated ?? null,
              permission: projectServerData.permission ?? [],
              project_type: projectServerData.project_type ?? 'N/A',
            })
          }
        });
        onSuccess(listOfPlatformBasedProjects);
      })
      .catch((error) => onFail(error.message));
  } else {
    // If the user.uid is not defined or the user company is empty
    onFail('User or company is not defined');
  }
}
