/**
 * @description - The language changing view.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// React
import { useState, MouseEvent } from 'react';
// @mui
import { MenuItem, Stack } from '@mui/material';
// hooks
import { useLocalStorage, useAuth } from '../../../hooks';
// components
import { Image, MenuPopover, IconButtonAnimate } from '../../../components';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export default function CompaniesSwitcherPopover() {
  const [selectedCompany, setSelectedCompany] = useLocalStorage('company', undefined);
  const { companies } = useAuth();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  if (companies && companies.length > 1) {
    return (
      <>
        <IconButtonAnimate
          onClick={handleOpen}
          sx={{
            width: 40,
            height: 40,
            fontSize: 6,
            ...(open && {bgcolor: 'action.selected'}),
          }}
        >
          <Image disabledEffect src={'/assets/icons/business.svg'} alt={selectedCompany.display_name ?? 'Company'}/>
          {selectedCompany.display_name ?? 'Company'}
        </IconButtonAnimate>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {px: 1, typography: 'body2', borderRadius: 0.75},
          }}
        >
          <Stack spacing={0.75}>
            {companies.map((company) => (
              <MenuItem
                key={company.uid}
                selected={company.uid === selectedCompany.uid}
                onClick={() => {
                  setSelectedCompany(company);
                  handleClose();
                  // Refreshing the page
                  window.location.reload();
                }}
              >
                {company.display_name}
              </MenuItem>
            ))}
          </Stack>
        </MenuPopover>
      </>
    );
  }

  return null;
}
