/**
 * @description - Routes variable for different platforms. Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Layouts
import { LogoOnlyLayout } from '../layouts';
// Paths
import { PATH_APP_AUTH, PATH_APP_REQUESTS } from './index';
// Types
import { TypeRouteItem } from '../@types';
// Components
import { Loadable, AuthGuard } from '../components';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Application selection Pages
const CreateRequestPage = Loadable(lazy(() => import('../pages/procurement/index')));
const ViewRequestPage = Loadable(lazy(() => import('../pages/procurement/ViewRequest')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Request routes list, contains list of RouteItem type objects.
 */
const requestsRoutes: TypeRouteItem = {
  path: 'procurement',
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <LogoOnlyLayout />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={'/procurement/list'} replace />, index: true },
    // Applications
    {
      path: 'list',
      element: <CreateRequestPage />
    },
    {
      path: 'view/:id',
      element: <ViewRequestPage />
    }
  ]
};

export default requestsRoutes;
