/**
 * @description - The Firebase Auth Context to provide authentication listener.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import {
  createContext,
  ReactNode,
  useEffect,
  useState
} from 'react';
// Firestore
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  collection,
  Timestamp
} from 'firebase/firestore';
// Local types
import {TypeDocumentContentItem, TypeDocument, TypeDocumentView} from '../../@types';
// Local hooks
import { useAuth } from '../../hooks';
// Configurations
import { documentPaths } from '../../configs';
// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The firebase documents context type. Contains all the necessary methods and props
 */
type FirebaseDocumentContext = {
  documents: TypeDocument[] | undefined | null;
  document: TypeDocumentContentItem[] | undefined | null;
  requisites: TypeDocumentContentItem[] | undefined | null;
  headers: TypeDocumentContentItem[] | undefined | null;
  footers: TypeDocumentContentItem[] | undefined | null;
  getDocuments: VoidFunction;
  getDocument: (uid: string) => void;
  getRequisites: (uid: string) => void;
  getHeaders: (uid: string) => void;
  getFooters: (uid: string) => void;
}

/**
 * @description - The initial state of the context
 */
const documentsContextInitialState: FirebaseDocumentContext = {
  documents: null,
  document: null,
  requisites: null,
  headers: null,
  footers: null,
  getDocuments: () => {},
  getDocument: () => {},
  getRequisites: () => {},
  getHeaders: () => {},
  getFooters: () => {},
}

/**
 * @description - Firebase Auth Context
 */
const FirebaseDocumentsContext = createContext<FirebaseDocumentContext>(
  documentsContextInitialState,
);

/**
 * @description - The method is generating document provider context for the next usage
 * @param children
 * @param document_type
 * @constructor
 */
function FirebaseDocumentsProvider({
  children,
  documentType
}: { children: ReactNode, documentType: TypeDocumentView }) {
  // State of the Firebase Document
  const [documents, setDocuments] = useState<TypeDocument[] | undefined | null>(documentsContextInitialState.documents);
  const [document, setDocument] = useState<TypeDocumentContentItem[] | undefined | null>(documentsContextInitialState.document);
  const [requisites, setRequisites] = useState<TypeDocumentContentItem[] | undefined | null>(documentsContextInitialState.document);
  const [headers, setHeaders] = useState<TypeDocumentContentItem[] | undefined | null>(documentsContextInitialState.document);
  const [footers, setFooters] = useState<TypeDocumentContentItem[] | undefined | null>(documentsContextInitialState.document);
  // Getting the current user
  const { user, companies } = useAuth();
  // Getting firestore db instance
  const firestoreDb = getFirestore();

  /**
   * @description - The method is fetching the latest documents from the server
   */
  const fetchDocuments = () => {
    if (user?.uid && companies && companies.length > 0) {
      const documentsDbReference = collection(
        firestoreDb,
        documentPaths.COLLECTION_DOCUMENTS
      );
      const companiesIds = companies.slice(0, 10).map((company) => company.uid);
      let documentsListQuery = query(
        documentsDbReference,
        where('active', '==', true),
        where('document_type', '==', documentType),
        where('company_uid', 'in', companiesIds),
        orderBy('created', 'desc'),
        limit(100),
      );
      getDocs(documentsListQuery)
        .then((querySnapshots) => {
          const listOfDocuments: TypeDocument[] = [];
          querySnapshots.forEach((doc) => {
            const documentData = doc.data();
            if (documentData) {
              listOfDocuments.push({
                uid: documentData.uid ?? 'not_found',
                display_name: documentData.display_name ?? 'Not Found',
                active: documentData.active,
                company_uid: documentData.company_uid,
                created: documentData.created,
                updated: documentData.updated,
                document_type: documentData.document_type,
                languages: documentData.languages,
                project_uid: documentData.project_uid ?? null,
              });
            }
          });
          if (listOfDocuments.length > 0) {
            setDocuments(listOfDocuments);
          }
        }).catch((error) => {
        console.log(error.message)});
    }
  };

  /**
   * @description - The method is fetching the latest documents from the server
   */
  const fetchDocument = (document_uid: string) => {
    if (user?.uid && companies && companies.length > 0) {
      const documentsDbReference = collection(
        firestoreDb,
        documentPaths.COLLECTION_DOCUMENTS,
        document_uid,
        documentPaths.COLLECTION_CONTENTS,
      );
      let documentsListQuery = query(
        documentsDbReference,
        orderBy('order', 'asc'),
        limit(100),
      );
      getDocs(documentsListQuery)
        .then((querySnapshots) => {
          const listOfContents: TypeDocumentContentItem[] = [];
          querySnapshots.forEach((doc) => {
            const documentData = doc.data();
            if (documentData) {
              listOfContents.push({
                uid: documentData.uid,
                type: documentData.type,
                format: documentData.format,
                style: documentData.style,
                order: documentData.order,
                created: documentData.created,
                updated: documentData.updated,
                values: documentData.values
              });
            }
          });
          if (listOfContents.length > 0) {
            setDocument(listOfContents);
          }
        }).catch((error) => {
        console.log(error.message)});
    }
  };

  /**
   * @description - The method is fetching the latest documents from the server
   */
  const fetchRequisite = (document_uid: string) => {
    if (user?.uid && companies && companies.length > 0) {
      const documentsDbReference = collection(
        firestoreDb,
        documentPaths.COLLECTION_DOCUMENTS,
        document_uid,
        'requisites',
      );
      let documentsListQuery = query(
        documentsDbReference,
        orderBy('order', 'asc'),
        limit(100),
      );
      getDocs(documentsListQuery)
        .then((querySnapshots) => {
          const listOfContents: TypeDocumentContentItem[] = [];
          querySnapshots.forEach((doc) => {
            const documentData = doc.data();
            if (documentData) {
              listOfContents.push({
                uid: documentData.uid,
                type: documentData.type,
                format: documentData.format,
                style: documentData.style,
                order: documentData.order,
                created: documentData.created,
                updated: documentData.updated,
                values: documentData.values
              });
            }
          });
          if (listOfContents.length > 0) {
            setRequisites(listOfContents);
          }
        }).catch((error) => {
        console.log(error.message)});
    }
  };

  /**
   * @description - The method is fetching the latest documents from the server
   */
  const fetchHeaders = (document_uid: string) => {
    if (user?.uid && companies && companies.length > 0) {
      const documentsDbReference = collection(
        firestoreDb,
        documentPaths.COLLECTION_DOCUMENTS,
        document_uid,
        'headers',
      );
      let documentsListQuery = query(
        documentsDbReference,
        orderBy('order', 'asc'),
        limit(100),
      );
      getDocs(documentsListQuery)
        .then((querySnapshots) => {
          const listOfContents: TypeDocumentContentItem[] = [];
          querySnapshots.forEach((doc) => {
            const documentData = doc.data();
            if (documentData) {
              listOfContents.push({
                uid: documentData.uid,
                type: documentData.type,
                format: documentData.format,
                style: documentData.style,
                order: documentData.order,
                created: documentData.created,
                updated: documentData.updated,
                values: documentData.values
              });
            }
          });
          if (listOfContents.length > 0) {
            setHeaders(listOfContents);
          }
        }).catch((error) => {
        console.log(error.message)});
    }
  };

  /**
   * @description - The method is fetching the latest documents from the server
   */
  const fetchFooters = (document_uid: string) => {
    if (user?.uid && companies && companies.length > 0) {
      const documentsDbReference = collection(
        firestoreDb,
        documentPaths.COLLECTION_DOCUMENTS,
        document_uid,
        'footers',
      );
      let documentsListQuery = query(
        documentsDbReference,
        orderBy('order', 'asc'),
        limit(100),
      );
      getDocs(documentsListQuery)
        .then((querySnapshots) => {
          const listOfContents: TypeDocumentContentItem[] = [];
          querySnapshots.forEach((doc) => {
            const documentData = doc.data();
            if (documentData) {
              listOfContents.push({
                uid: documentData.uid,
                type: documentData.type,
                format: documentData.format,
                style: documentData.style,
                order: documentData.order,
                created: documentData.created,
                updated: documentData.updated,
                values: documentData.values
              });
            }
          });
          if (listOfContents.length > 0) {
            setFooters(listOfContents);
          }
        }).catch((error) => {
        console.log(error.message)});
    }
  };

  // On page initialization will try to fetch the documents
  useEffect(fetchDocuments, [user, companies])

  /**
   * @description - The view of the Firebase Auth Context Hook method
   */
  return (
    <FirebaseDocumentsContext.Provider
      value={{
        documents,
        document,
        requisites,
        footers,
        headers,
        getDocuments: fetchDocuments,
        getDocument: fetchDocument,
        getRequisites: fetchRequisite,
        getHeaders: fetchHeaders,
        getFooters: fetchFooters,
      }}
    >
      {children}
    </FirebaseDocumentsContext.Provider>
  );
}

export { FirebaseDocumentsProvider, FirebaseDocumentsContext };
